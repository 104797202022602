import { TypographyHeading, TypographyParagraph } from '../typography'
import { twMerge } from 'tailwind-merge'
import Image from './Image'
import { chainIDMapping } from '#app/utils/misc'

const NftCard = ({
	name,
	image,
	price,
	currency,
	collectionName,
	artistName,
	chain,
	cardAdditionalProps = '',
	containerAdditionalProps = '',
	imageAdditionalProps = '',
}: {
	name: string
	image: string
	price?: string
	currency?: string
	collectionId?: string
	collectionName?: string
	artistName?: string
	chain?: string
	contract: string
	tokenId: string
	cardAdditionalProps: string
	containerAdditionalProps: string
	imageAdditionalProps: string
}) => {
	let priceToDisplay = price
	if (price && price.length > 8) {
		priceToDisplay = `> ${price.slice(0, 7)}`
	}

	let chainToDisplay = chain
	// @ts-ignore
	if (chain && chainIDMapping[chain]) {
		// @ts-ignore
		chainToDisplay = chainIDMapping[chain]
	}
	return (
		<div
			className={twMerge(
				'group h-[371px] w-66 border border-textInactiveLight hover:border-textPrimaryLight group-hover:border-textPrimaryLight dark:border-textInactiveDark dark:hover:border-textPrimaryDark dark:group-hover:border-textPrimaryDark',
				cardAdditionalProps,
			)}
		>
			<Image
				className={twMerge('h-72 w-66', imageAdditionalProps)}
				containerClassName={containerAdditionalProps}
				imageUrl={image}
				imageAlt={name}
			></Image>
			<TypographyHeading
				size="xSmall"
				isBold={true}
				additionalClasses="group-hover:underline truncate px-4 py-2 w-full text-left text-textPrimaryLight dark:text-textPrimaryDark"
			>
				{name}
			</TypographyHeading>
			<div className="flex flex-row border-t border-textInactiveLight group-hover:border-textPrimaryLight dark:border-textInactiveDark dark:group-hover:border-textPrimaryDark">
				<div
					className={`flex ${(currency && price) || chain ? 'w-1/2 border-r' : 'w-full'} items-center justify-between border-textInactiveLight px-4 py-2 group-hover:border-textPrimaryLight dark:border-textInactiveDark dark:group-hover:border-textPrimaryDark`}
				>
					<TypographyParagraph
						size="small"
						additionalClasses="pt-0.5 ml-1 truncate text-left text-textSecondaryLight dark:text-textSecondaryDark group-hover:text-textPrimaryLight dark:group-hover:text-textPrimaryDark"
					>
						{collectionName
							? collectionName
							: artistName
								? artistName
								: 'Unknown'}
					</TypographyParagraph>
				</div>
				{currency && price && (
					<div className="flex w-1/2 items-center justify-between px-4 py-2">
						<TypographyParagraph
							size="small"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
						>
							{currency}
						</TypographyParagraph>
						<TypographyParagraph
							size="small"
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark line-clamp-3"
						>
							{priceToDisplay}
						</TypographyParagraph>
					</div>
				)}
				{chain && (
					<div className="flex w-1/2 items-center justify-between px-4 py-2">
						<TypographyParagraph
							size="small"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark truncate"
						>
							Chain
						</TypographyParagraph>
						<TypographyParagraph
							size="small"
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark truncate"
						>
							{chainToDisplay}
						</TypographyParagraph>
					</div>
				)}
			</div>
		</div>
	)
}

export default NftCard
